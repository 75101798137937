import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Context from "../../state/context";
import { FacebookShareButton, InstagramShareButton, TwitterShareButton } from "react-share";
import { ReactComponent as FB } from "../../icons/fb.svg";
import { ReactComponent as Insta } from "../../icons/insta.svg";
import { ReactComponent as Twitter } from "../../icons/twitter.svg";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export function ShareModal(props) {
  const { updateCurrentPage } = useContext(Context);

  const handleClose = () => {
    props.onHide();
  };

  const title = "The Most Beautiful Sound";
  const description = "The Most Beautiful Sound is a scientific breakthrough that, for the first time ever, has captured the sound of cancer cells dying.";
  const url = "https://www.themostbeautifulsound.org/";

  return (
    <Modal {...props} size='sm' aria-labelledby='contained-modal-title-vcenter' className='share-modal' centered onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className='text-center'>
        <h3 className='mb-4 text-white'>Share</h3>
        <Row>
          <Col>
            <FacebookShareButton url={url} quote={title}>
              <FB />
            </FacebookShareButton>
          </Col>
          <Col>
            <TwitterShareButton url={url} title={title}>
              <Twitter />
            </TwitterShareButton>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
