import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';
import * as THREE from 'three';
import { gsap, Sine, Power1, Expo } from 'gsap';
import styled from 'styled-components'
import Context from '../../state/context';

const Canvas = styled.canvas`
    z-index: 5;
`

const ThreeAnimation = ({ animationComplete,clickInteraction, page }) => {

  const canvasRef = useRef(null);
  const { state, updateIsLoading } = useContext(Context);
  const [animating, setAnimating] = useState(0);
  const [currentPagePosition, setCurrentPagePosition ] = useState(1)

  useEffect(() => {

    updateIsLoading(false);

    //Setup Threejs constants
    let clock = new THREE.Clock();
    let delta = 0, mouse = {}, target = {};
    mouse.x = window.innerWidth/2;
    mouse.y = window.innerHeight/2;
    mouse.movementY = 0;
    mouse.movementX = 0;
    target.x = mouse.x;
    target.y = mouse.y;

    const FOG_NEAR = 9;
    const FOG_FAR = 12;

    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();
    pointer.x = null;
    pointer.y = null;

    //helper functions
    const degToRad = (deg) => {
      return deg * (Math.PI / 180) / 2
    }

    // Create a scene
    const scene = new THREE.Scene();

    //Fog
    scene.fog = new THREE.Fog(0x000000, FOG_NEAR, FOG_FAR)

    // Create a camera
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      .1,
      1000
    );

    //Set initial camera conditions
    camera.position.set(-100, 0, 0);
    // camera.lookAt(0, 0, 0);
    camera.rotation.y = -7.495086591666918;

    //Set initial animation into scene
    gsap.to(camera.position, {
      x: 9,
      duration: 4,
      ease: Power1.easeOut, onComplete: () => {
        animationComplete()
      }
    })

    //Lighting setup
    const ambient = new THREE.HemisphereLight(0x404040, 0x080820, 2);
    scene.add(ambient);

    const light = new THREE.AmbientLight( 0x404040, 2 ); // soft white light
    scene.add( light );

    // Create a renderer
    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, antialias: true, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.autoClear = false;
    renderer.setClearColor(0x000000, 0.0);

    //Space Sky
    const sky_geometry = new THREE.SphereGeometry(500, 60, 40);
    sky_geometry.scale(- 1, 1, 1);
    const texture = new THREE.TextureLoader().load('images/scene/universe.jpg');
    const sky_material = new THREE.MeshBasicMaterial({
      map: texture,
      fog: false,
      transparent: true,
      opacity: 1
    });

    const sky_mesh = new THREE.Mesh(sky_geometry, sky_material);
    sky_mesh.rotation.x = 90
    sky_mesh.rotation.y = 90
    scene.add(sky_mesh);

    //Galaxies
    const galaxies = [
      'images/galaxies/sttenio_cancer_cell_exploding_in_white_bright_surface_cb.jpg',
      'images/galaxies/sttenio_cancer_cell_exploding_like_fireworks_with_backlight_e6.jpg',
      'images/galaxies/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_32.jpg',
      'images/galaxies/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_44.jpg',
      'images/galaxies/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_50.jpg',
      'images/galaxies/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_84.jpg',
      'images/galaxies/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_ca.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_07.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_2a.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_3e.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_3f.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_3f6.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_42.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_4c.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_59.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_5a.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_5b.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_6f.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_71.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_80.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_87.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_97.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_ca.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_da.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_db.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_db1.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_fd.jpg',
      'images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_white_background_a4.jpg'
    ];

    const galaxy_geometry = new THREE.BoxGeometry(0.001, 1.2, 1.2);

    for (var i = 0; i < 100; i++) {
      const textureLoader = new THREE.TextureLoader();
      const galaxy_texture = textureLoader.load(galaxies[Math.floor(Math.random() * (galaxies.length))]);
      const galaxy_material = new THREE.MeshLambertMaterial({
        map: galaxy_texture,
        blending: 1,
        transparent: true,
        fog: false
      })

      let cube = new THREE.Mesh(galaxy_geometry, galaxy_material);
      cube.position.set(
        Math.random() * 250 - 0,
        Math.random() * 80 - 40,
        Math.random() * 80 - 40
      )
      scene.add(cube);
    }

    //straight path
    const path = new THREE.CatmullRomCurve3(
      [
        new THREE.Vector3(20, 0, 0),
        new THREE.Vector3(30, 0, 0),
        new THREE.Vector3(40, 0, 0),
        new THREE.Vector3(50, 0, 0),
        new THREE.Vector3(60, 0, 0),
        new THREE.Vector3(70, 0, 0)
      ]
    );

    const box_material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    const box_geometry = new THREE.PlaneGeometry(1, 1, 32, 32);

    const cell_container = new THREE.Group();
    scene.add(cell_container);

    const cells = [
      { img: 'images/cells/sttenio_cancer_cell_exploding_like_fireworks_with_backlight_e6.jpg', alpha: 'images/cells/sttenio_cancer_cell_exploding_like_fireworks_with_backlight_e6_Alpha_1.jpg' },
      { img: 'images/cells/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_84.jpg', alpha: 'images/cells/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_84_Alpha_1.jpg' },
      { img: 'images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_2a.jpg', alpha: 'images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_2a_Alpha.jpg' },
      { img: 'images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_5a.jpg', alpha: 'images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_5a_Alpha.jpg' },
      { img: 'images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_80.jpg', alpha: 'images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_80_Alpha.jpg' },
      { img: 'images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_da_1.jpg', alpha: 'images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_da_Alpha.jpg' }
    ]

    const poster_geometry = new THREE.PlaneGeometry(16 / 10, 9 / 10, 32, 32);
    const posterLoader = new THREE.TextureLoader();
    const poster_texture = posterLoader.load('images/poster/poster.jpg');
    const poster_material = new THREE.MeshLambertMaterial({
      map: poster_texture,
      side: THREE.DoubleSide
    })

    const poster = new THREE.Mesh(poster_geometry, poster_material);
    poster.name = 'video';
    poster.position.set(11, 0, 0)
    poster.rotation.y = degToRad(-180);
    cell_container.add(poster);

    for (var i = 0; i < path.points.length; i++) {
      const textureLoader = new THREE.TextureLoader();
      const cell_texture = textureLoader.load(cells[i].img);
      const cell_alpha_texture = textureLoader.load(cells[i].alpha);

      const cell_material = new THREE.MeshPhongMaterial({
        map: cell_texture,
        alphaMap: cell_alpha_texture,
        blending: 1,
        transparent: true,
        side: THREE.DoubleSide
      })

      let cube = new THREE.Mesh(box_geometry, cell_material);
      cube.name = `cell${i}`;
      cube.rotation.y = degToRad(180);
      const position = i % 2 ? 0.25 : -0.25;
      const positionY = i % 2 ? 0.3 : -0.3;
      cube.position.set(path.points[i].x, path.points[i].y + positionY, path.points[i].z + position)
      cell_container.add(cube);
    }

    path.curveType = 'catmullrom';
    path.closed = false;
    const geometry = new THREE.TubeGeometry(path, 300, 2, 50, false);
    const material = new THREE.MeshBasicMaterial({ wireframe: false, fog: true, color: 0x4c00b0, transparent: true, opacity: 0 });
    const tube = new THREE.Mesh(geometry, material);
    scene.add(tube);

    //Functions
    const onResize = (ev) => {
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    const onMouseMove = (ev) => {
      mouse.x = (ev.clientX - window.innerWidth / 2);
      mouse.y = (ev.clientY - window.innerHeight / 2);
    }

    const onMouseDown = (ev) => {
      pointer.x = (ev.clientX / window.innerWidth) * 2 - 1;
      pointer.y = - (ev.clientY / window.innerHeight) * 2 + 1;
    }

    const gsapTimeline = (reference) => {
      setAnimating(1);
      gsap.to(camera.position, {
        x: ((reference) * 10) - 1,
        duration: 1.5,
        ease: Sine.easeOut,
        onComplete: () => {
          setAnimating(0)
          animationComplete('finished')
        }
      })
    }

    const updateCamera = () => {
      target.x = (1 - mouse.x) * 0.00009;
      target.y = (1 - mouse.y) * 0.00009;
      camera.rotation.x += 0.05 * (target.y - camera.rotation.x);
      camera.rotation.y += 0.05 * (target.x - camera.rotation.y);
      camera.rotation.y = camera.rotation.y + degToRad(-45);
    }

    let rayThrottle = false;

    const ray = () => {
      if (pointer.x != null && pointer.y != null) {
        raycaster.setFromCamera(pointer, camera);
        const intersects = raycaster.intersectObjects(cell_container.children);
        for (let i = 0; i < intersects.length; i++) {
          if (!rayThrottle) {
            rayThrottle = true;
            pointer.x = null;
            pointer.y = null;
            //intersects[i].object.material.color.set(0xff0000);
            console.log(intersects[i].object.name)
            clickInteraction(intersects[i].object.name)
            setTimeout(() => { 
              pointer.x = null;
              pointer.y = null;
              rayThrottle = false;
            }, 3000);
          }
        }
      }
    }

    // Render the scene
    const animate = () => {
      const time = clock.getElapsedTime();
      requestAnimationFrame(animate);
      updateCamera();
      ray();
      renderer.render(scene, camera);
    };
    animate();

    document.addEventListener('animationReq', (data) => {
      console.log(data.detail)
      if (animating === 0) {
        gsapTimeline(data.detail)
      }
    });

    window.addEventListener('resize', onResize)
    window.addEventListener('mousemove', onMouseMove)
    const canvasElement = document.getElementById('canvas');
    canvasElement.addEventListener('click', onMouseDown);
    canvasElement.addEventListener('touchstart', onMouseDown)

  }, []);

  useEffect(() => {
    if(page !== currentPagePosition) {
      const event = new CustomEvent('animationReq', { detail: page });
      document.dispatchEvent(event);
    }
    setCurrentPagePosition(page)
}, [page, currentPagePosition, setCurrentPagePosition]);

  return <Canvas id="canvas" ref={canvasRef} />;
};

export default ThreeAnimation;