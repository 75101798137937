import React, { useEffect, useContext, useState, useRef } from "react";
import styled from "styled-components";
import Context from "../../state/context";
import { motion } from "framer-motion";
import { ReactComponent as SoundOnSvg } from "../../icons/sound-on.svg";
import { ReactComponent as SoundOffSvg } from "../../icons/sound-off.svg";
import { ReactComponent as SoundPauseSvg } from "../../icons/pause.svg";
import { ReactComponent as SoundPlaySvg } from "../../icons/play.svg";
import Wave from "../../lottie/Wave-Progress-Grad2.json";
import { Player } from "@lottiefiles/react-lottie-player";
import Transcript from "./transcript";
import { PlayAudioBtnDefault, ScrollToNext } from "../button/button";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

const LottiePlayer = styled(Player)`
  height: 100px;
  width: auto;
  width: 75%;
`;

const CancerPlayer = styled.button`
  position: absolute;
  top: 18px;
  left: -28px;
  z-index: 2;
`;

function Content({ story }) {
  const { state, updateCurrentContentPosition, updateToggleBackgroundAudio } = useContext(Context);
  const { title, name, copy, audioStory, audioCancer, positionContent } = story;

  const audioStoryRef = useRef(null);
  const audioCancerRef = useRef(null);
  const lottieRef = useRef(null);
  const [isPlayingStory, setIsPlayingStory] = useState(false);
  const [isPlayingCancer, setIsPlayingCancer] = useState(false);
  const [cancerCurrentTime, setCancerCurrentTime] = useState(0);
  const [cancerDuration, setCancerDuration] = useState(0);
  const [firstPlay, setFirstPlay] = useState(false);

  const handleLoadedMetadata = (event) => {
    setCancerDuration(event.target.duration);
  };

  const handleCancerTimeUpdate = () => {
    setCancerCurrentTime(audioCancerRef.current.currentTime);
  };

  useEffect(() => {
    setIsPlayingCancer(true);
    audioCancerRef.current.play();
    updateCurrentContentPosition(positionContent);
  }, [positionContent]);

  useEffect(() => {
    if (isPlayingCancer) {
      const audio = audioCancerRef.current;
      if (audio) {
        audio.addEventListener("timeupdate", handleCancerTimeUpdate);
        return () => {
          audio.removeEventListener("timeupdate", handleCancerTimeUpdate);
        };
      }
    }
  }, [audioCancerRef, isPlayingCancer]);

  const lottieFrames = 523;
  let perc = Math.floor((cancerCurrentTime / cancerDuration) * 100);
  let percLottieFramer = Math.floor((perc / 100) * lottieFrames);
  if (lottieRef.current != null) {
    lottieRef.current.setSeeker(`${percLottieFramer}`, false);
  }

  const handlePlayStory = () => {
    setIsPlayingCancer(false);
    setIsPlayingStory(false);
    updateToggleBackgroundAudio(false);
    setFirstPlay(true);

    if (audioStoryRef.current.paused) {
      setIsPlayingStory(true);
      audioStoryRef.current.play();
      audioCancerRef.current.pause();
    } else {
      setIsPlayingStory(false);
      audioStoryRef.current.pause();
    }
  };

  const handleCancerEnded = () => {
    setIsPlayingCancer(false);
  };

  const handlePlayCancer = () => {
    setIsPlayingCancer(false);
    setIsPlayingStory(false);
    updateToggleBackgroundAudio(false);

    if (audioCancerRef.current.paused) {
      setIsPlayingCancer(true);
      audioCancerRef.current.play();
      audioStoryRef.current.pause();
    } else {
      setIsPlayingCancer(false);
      audioCancerRef.current.pause();
    }
  };

  const AudioComponent = ({ src, onLoadedMetadata, refProp }) => {
    return <audio ref={refProp} src={src} onLoadedMetadata={onLoadedMetadata} />;
  };

  return (
    <>
      <div className={`story-content ${positionContent}`}>
        <div className={`story-body`}>
          <div className='cancer-audio'>
            <CancerPlayer className='button btn-icon-emphasis' onClick={handlePlayCancer}>
              {isPlayingCancer ? <SoundPauseSvg /> : <SoundPlaySvg />}
            </CancerPlayer>
            <LottiePlayer id='lottie-container' src={Wave} autoplay={false} speed={50} loop={false} ref={lottieRef} />
            <audio ref={audioCancerRef} src={audioCancer} onLoadedMetadata={handleLoadedMetadata} onEnded={handleCancerEnded} />
          </div>
          <h1 className='heading'>{title}</h1>
          <audio ref={audioStoryRef} src={audioStory} />
          <PlayAudioBtnDefault label={name} handlePlay={handlePlayStory} isPlaying={isPlayingStory} />
          <Transcript isPlayingStory={isPlayingStory} audioStoryRef={audioStoryRef} copy={copy} />
        </div>
      </div>
      {!firstPlay && (
        <>
          <BrowserView>{state.currentPage === 2 && <ScrollToNext text='Scroll to travel to the next cell' />}</BrowserView>
          <MobileView>{state.currentPage === 2 && <ScrollToNext text='Swipe to travel' />}</MobileView>
        </>
      )}
    </>
  );
}

export default Content;
