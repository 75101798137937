import PropTypes from "prop-types";
import styled from "styled-components";
import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Nav from "../components/nav/nav";
import Context from "../state/context";
import { PlayAudioBtnDefault } from "../components/button/button";
import { Howl } from "howler";

const ContentBody = styled.div`
  color: white;
  text-align: left;
  position: absolute;
  max-width: 850px;
  width: 100%;
  max-height: 85vh;
  overflow: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #4d4d4d;
  padding: 40px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
`;

const CloseBtn = styled(Link)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const audioClip = new Howl({
  src: ["./audio/Dr.ConorEvans.mp3"],
});

function About() {
  const { updateCurrentPage,updateToggleBackgroundAudio } = useContext(Context);
  const [isPlaying, setIsPlaying] = useState(false);


  const handleClick = () => {
    updateCurrentPage(1);
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioClip.pause();
    } else {
      audioClip.play();
      updateToggleBackgroundAudio(false);

    }
    setIsPlaying(!isPlaying);
  };


  return (
    <motion.div
      transition={{
        delay: 0.5,
        duration: 0.5,
        default: { ease: "easeInOut" },
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ContentBody>
        <div className='container about'>
          <CloseBtn to='/home' onClick={handleClick}>
            <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M20.6663 12.2733L19.7263 11.3333L15.9997 15.06L12.273 11.3333L11.333 12.2733L15.0597 16L11.333 19.7266L12.273 20.6666L15.9997 16.94L19.7263 20.6666L20.6663 19.7266L16.9397 16L20.6663 12.2733Z'
                fill='white'
              />
              <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='white' />
            </svg>
          </CloseBtn>
          <div className='row my-7'>
            <div className='col'>
              <h1 className='mb-7'>About</h1>
              <p className='lead'>
                The Most Beautiful Sound is a scientific breakthrough that, for the first time ever, has captured the sound of cancer cells being destroyed.
              </p>
              <p>It's a sound that will bring hope and resilience to millions of people living with cancer.</p>
              <p>It's a sound that we believe every person living with cancer deserves to hear.</p>
              <hr className='my-9' />
              <h2 className='my-7 text-center'>A new sound in oncology</h2>
              <p className='lead text-center'>Working with the world's top medical institution, the team launched a scientifically-validated research study to see if the sound of cancer cells being destroyed could be captured.</p>
            </div>
          </div>
          <div className='row my-7 mt-3 text-center'>
            <div className='col'>
              <img className='mb-3' src='./icons/mbs-petri-dish-02.svg' width={"80px"} height={"auto"} alt='' />
              <p className='small'>We isolated breast and lung cancer cells at the precise moment of cellular death</p>
            </div>
            <div className='col'>
              <img className='mb-3' src='./icons/mbs-laser-beam.svg' width={"80px"} height={"auto"} alt='' />
              <p className='small'>
                Using stimulated Ramen Scattering, laser beams of different colors were projected onto cells to capture their molecular vibrations
              </p>
            </div>
            <div className='col'>
              <img className='mb-3' src='./icons/mbs-musical-scale.svg' width={"80px"} height={"auto"} alt='' />
              <p className='small'>The data was translated into high fidelity sound </p>
            </div>
            <div className='col'>
              <img className='mb-3' src='./icons/mbs-headphones.svg' width={"80px"} height={"auto"} alt='' />
              <p className='small'>Patients with cancer listened to the sounds as inspiration for hope and resilience</p>
            </div>
          </div>

          <div className='row my-7 text-center video-panel'>
            <div className='col'>
              <h3 className='mb-3'>Conor Evans, Ph.D.</h3>
              <p className='video'>Associate Professor, Harvard Medical School Wellman Center for Photomedicine, Massachusetts General Hospital</p>
              <p>Conor Evans, Ph.D. was the principal investigator and the research study was performed in his lab under his direct supervision.</p>
              <p>
                "There’s motion and vibration within all cells, including those with cancer. Using a Stimulated Ramen scattering technique, we measured the
                movement of breast and lung cells at the precise moment of cellular death, and transcribed those motions into sounds you can hear. The idea of
                taking something invisible and giving it sound and giving it perspective, giving it the ability to be perceived struck me as something that
                could be very powerful for patients in their journey fighting cancer. "
              </p>

            <PlayAudioBtnDefault
              label={isPlaying ? "Pause" : "Listen"}
              handlePlay={togglePlay}
              isPlaying={isPlaying}
            />
            </div>
          </div>
        </div>
      </ContentBody>
      <Nav />
    </motion.div>
  );
}
export default About;
