import PropTypes from 'prop-types'
import styled from 'styled-components'
import React, { Component, useRef, useState, useEffect, useContext} from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import Wave from "../../lottie/Wave-Progress-Grad2.json";
import { motion, sync } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom'
import Context from '../../state/context';
import {Button} from '../button/button';
import Nav from '../nav/nav'
import {Campaign, FooterLogo} from '../campaign/campaign'
import { ReactComponent as SoundOnSvg } from '../../icons/sound-on-sm.svg';

const Headphones = styled.p`
  color: white;
  margin-top: 40px !important;
`

const Enter = styled.a`
  display: block;
  color: white;
  cursor: pointer;
  margin-top: 40px !important;
`
const LoaderWrapper = styled.div`
  width: 400px;
  height: auto;
  position: absolute;
  top: 50%;
  left: calc(50vw - 200px);
  transform: translateY(-50%);
`
const LoaderPage = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background-color: black;
  text-align: center;
  position: relative;
  z-index: 5;
`;

const LottiePlayer = styled(Player)`
  height: 100px;
  width: auto;
`;

export default function Loader () {

  const navigate = useNavigate();
  const { state, updateIsLoading} = useContext(Context);
  const PRELOAD_DELAY = 2000;
  let counter = 0;

  const playerRef = useRef(null);

  useEffect(() => {

    const assets = [
      { src: "images/cells/sttenio_cancer_cell_exploding_like_fireworks_with_backlight_e6.jpg", type: "image" },
      { src: "images/cells/sttenio_cancer_cell_exploding_like_fireworks_with_backlight_e6_Alpha_1.jpg", type: "image" },
      { src: "images/cells/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_84.jpg", type: "image" },
      { src: "images/cells/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_84_Alpha_1.jpg", type: "image" },
      { src: "images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_2a.jpg", type: "image" },
      { src: "images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_2a_Alpha.jpg", type: "image" },
      { src: "images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_5a.jpg", type: "image" },
      { src: "images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_5a_Alpha.jpg", type: "image" },
      { src: "images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_80.jpg", type: "image" },
      { src: "images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_80_Alpha.jpg", type: "image" },
      { src: "images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_da_1.jpg", type: "image" },
      { src: "images/cells/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_da_Alpha.jpg", type: "image" },
      { src: "images/galaxies/sttenio_cancer_cell_exploding_in_white_bright_surface_cb.jpg", type: "image" },
      { src: "images/galaxies/sttenio_cancer_cell_exploding_like_fireworks_with_backlight_e6.jpg", type: "image" },
      { src: "images/galaxies/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_32.jpg", type: "image" },
      { src: "images/galaxies/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_44.jpg", type: "image" },
      { src: "images/galaxies/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_50.jpg", type: "image" },
      { src: "images/galaxies/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_84.jpg", type: "image" },
      { src: "images/galaxies/sttenio_glowing_holographic_cancer_cell_exploding_in_the_dark_ca.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_07.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_2a.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_3e.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_3f.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_3f6.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_42.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_4c.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_59.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_5a.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_5b.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_6f.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_71.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_80.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_87.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_97.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_ca.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_da.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_db.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_db1.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_the_dark_fd.jpg", type: "image" },
      { src: "images/galaxies/sttenio_hologram_of_cancer_cell_exploding_in_white_background_a4.jpg", type: "image" }
    ];

    cacheAssets(assets);

  }, []);

  const cacheAssets = async(srcArray) => {

    const promises = await srcArray.map((ass, i) => {

      return new Promise(function (resolve, reject) {

        let asset;

        switch (ass.type) {
          case "image":
            asset = new Image();
            break;
          case "audio":
            asset = new Audio();
            break;
        }

        asset.src = ass.src;
        asset.onload = function() {
          resolve();
        }
        asset.onerror = function() {
          reject();
        }

      });
    });

    await Promise.all(promises);

    setTimeout(() => {
      updateIsLoading(false);
    }, PRELOAD_DELAY);

    playerRef.current.play();
    playerRef.current.setPlayerSpeed(50);

  };

  const enterSite = () => {
    navigate('/home');
  };

  return (
    <LoaderPage
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{opacity: 0}}
    >
      <LoaderWrapper>
        <LottiePlayer
          src={Wave}
          autoplay={false}

          loop={false}
          keepLastFrame={true}
          ref={playerRef}
        />
        <Headphones>Turn on sound for full experience!</Headphones>
        <Enter onClick={enterSite} >{state.isLoading ? '' : <Button icon={<SoundOnSvg />} text="Sound on" style="medium" /> }</Enter>
      </LoaderWrapper>
      <Nav />
      <Campaign />
      <FooterLogo />
    </LoaderPage>
  )
}