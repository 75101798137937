import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Howl, Howler } from 'howler';
import ambient from './ambient.mp3';
import sfx from './2000.mp3';
import Context from '../../state/context';

export default function Audio() {

  const { state } = useContext(Context);

  const [sound, setSound] = useState(null);
  const [soundSFX, setSoundSFX] = useState(null);

  //Ambient sounds...
  useEffect(() => {

    const ambientSound = new Howl({
      src: [ambient],
      volume: 0.05,
      autoplay: true,
      loop: true
    });

    setSound(ambientSound);

    if(state.toggleBackgroundAudio) {
        ambientSound.play()
    } else {
        ambientSound.pause();
    }

    return () => {
      ambientSound.unload();
    };

  }, [ambient, state.toggleBackgroundAudio]);

  return null
}