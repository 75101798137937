import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as SoundPauseSvg } from "../../icons/pause.svg";
import { ReactComponent as SoundPlaySvg } from "../../icons/play.svg";
import { ReactComponent as ScrollSvg } from "../../icons/scroll-ui-svgrepo-com.svg";
import clickSound from "./../../audio/mixkit-cool-interface-click-tone-2568.wav";
import hoverSound from "./../../audio/mixkit-plastic-bubble-click-1124.wav";
import { ShareModal } from "../modal/modalShare";

export function Button({ icon, text, style, link, className, blank=false, onClick = () => {} }) {
  const hoverAudio = new Audio(hoverSound);
  const clickAudio = new Audio(clickSound);
  hoverAudio.volume = 0.2;
  clickAudio.volume = 0.2;

  const playHoverSound = () => {
    hoverAudio.play();
  };

  const playClickSound = () => {
    clickAudio.play();
  };

  const handleClick = () => {
    onClick();
    playClickSound();
  };

  if (link) {
    return (
      <Link to={link} target={blank ? "_blank" : "_self"} className={`button btn-${style}-emphasis ${className}`} onMouseEnter={playHoverSound} onClick={handleClick}>
        {icon} <span>{text}</span>
      </Link>
    );
  } else {
    return (
      <>
        <button className={`button btn-${style}-emphasis ${className}`} onMouseEnter={playHoverSound} onClick={handleClick}>
          {icon} <span>{text}</span>
        </button>
      </>
    );
  }
}

export function ShareButton({ icon, text, style, link, className }) {
  const hoverAudio = new Audio(hoverSound);
  const clickAudio = new Audio(clickSound);
  hoverAudio.volume = 0.2;
  clickAudio.volume = 0.2;

  const [shareModalShow, setShareModalShow] = useState(false);

    const playHoverSound = () => {
    hoverAudio.play();
  };

  const playClickSound = () => {
    clickAudio.play();
  };

  const handleShare = () => {
    setShareModalShow(true);
  };


  const handleClick = () => {
      playClickSound();
      handleShare();
    }


    return (
      <>
        <button className={`button btn-${style}-emphasis ${className}`} onMouseEnter={playHoverSound} onClick={handleClick}>
          {icon} <span>{text}</span>
        </button>
        <ShareModal show={shareModalShow} onHide={() => setShareModalShow(false)} />
      </>
    );
}


export function PlayAudioBtnDefault({ label, handlePlay, isPlaying }) {
  return (
    <>
      <div className='transcript-audio text-tiny'>
        <button className='button btn-icon-emphasis' onClick={handlePlay}>
          {isPlaying ? <SoundPauseSvg /> : <SoundPlaySvg />}
        </button>
        {label}
      </div>
    </>
  );
}

export function ScrollToNext({ text }) {
  return (
    <button style={{"width": "80%"}} className='button scroll btn-icon-emphasis'>
      <ScrollSvg /> <span>{text}</span>
    </button>
  );
}

Button.defaultProps = {
  icon: "",
  text: "Click me",
  style: "high",
};
