import PropTypes from "prop-types";
import styled from "styled-components";
import React, { Component, useRef, useState, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ThreeAnimationDesktop from "../components/threeAnimation/threeAnimationDesktop";
import ThreeAnimationMobile from "../components/threeAnimation/threeAnimationMobile";
import Context from "../state/context";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import Paging from "../components/pagination/pagination";
import Content from "../components/content/content";
import { VideoModal } from "../components/modal/modalVideo";

function Home() {
  const { state, updateCurrentPage, updatePosition3d, updateIsVideoPlaying, updateToggleBackgroundAudio } = useContext(Context);
  const {
    currentPage,
    totalPages,
    currentContentPosition,
    content: { stories },
  } = state;

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [wheelStart, setWheelStart] = useState(null);
  const [wheelThrottle, setWheelThrottle] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onMouseWheel = (e) => {
    if (!wheelThrottle) {
      setWheelThrottle(true);
      //e.preventDefault()
      if (e.deltaY > wheelStart) {
        if (currentPage <= 6 && !modalShow) {
          updateCurrentPage(currentPage + 1);
        } else {
          //updateCurrentPage(1);
        }
      } else {
        if (currentPage > 1) {
          updateCurrentPage(currentPage - 1);
        } else {
          //updateCurrentPage(7);
        }
      }
      setTouchStart(e.deltaY);
      setTimeout(() => setWheelThrottle(false), 3000);
    }
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isUpSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    if (isUpSwipe || isDownSwipe) {
      if (isUpSwipe) {
        if (currentPage > 1) {
          updateCurrentPage(currentPage - 1);
        } else {
          //updateCurrentPage(7);
        }
      } else {
        if (currentPage <= 6) {
          updateCurrentPage(currentPage + 1);
        } else {
          //updateCurrentPage(1);
        }
      }
    }
  };

  const animationCompleteCallback = (msg) => {
    console.log("animationCompleteCallback", msg);
    //updatePosition3d(false)
  };

  const clickInteraction = (msg) => {
    if (msg === "video" && currentPage === 1) {
      setModalShow(true);
      updateToggleBackgroundAudio(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    updateCurrentPage(pageNumber);
  };

  const renderedContent = stories
    .filter((story, index) => {
      return index !== 0 && index === currentPage - 1;
    })
    .map((story, index) => {
      const { positionContent } = story;
      return (
        <AnimatePresence mode='wait' key={positionContent}>
          <motion.div
            key={positionContent}
            variants={{
              start: {
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              },
              mid: {
                pacity: 0,
                transition: {
                  duration: 0.5,
                  delay: 0.5,
                },
              },
              end: {
                opacity: 1,
                transition: {
                  duration: 0.5,
                  delay: 1.2,
                },
              },
            }}
            initial={{ opacity: 0 }}
            animate={["start", "mid", "end"]}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeOut" }}
          >
            <Content key={positionContent} story={story} />
          </motion.div>
        </AnimatePresence>
      );
    });

  return (
    <motion.div
      transition={{
        delay: 1,
        opacity: { duration: 1 },
        default: { ease: "easeInOut" },
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onWheel={onMouseWheel}
    >
      <BrowserView>
        <Paging totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
        <ThreeAnimationDesktop page={currentPage} clickInteraction={clickInteraction} animationComplete={animationCompleteCallback} />
        {renderedContent}
        <VideoModal show={modalShow} onHide={() => setModalShow(false)} />
      </BrowserView>
      <MobileView className='home-mobile'>
        <Paging totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
        <ThreeAnimationMobile page={currentPage} clickInteraction={clickInteraction} animationComplete={animationCompleteCallback} />
        {renderedContent}
        <VideoModal show={modalShow} onHide={() => setModalShow(false)} />
      </MobileView>
    </motion.div>
  );
}

export default Home;
