import React, { useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import Context from "../../state/context";

export function VideoModal(props) {

  const { updateCurrentPage } = useContext(Context);

  const handleClose = () => {
    props.onHide();
    updateCurrentPage(2);
  };

  const handleVideoEnded = () => {
    handleClose();
  };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen
      onHide={handleClose}
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <video
          src="./video/B411836_Grey_ASCO_The_Most_Beautiful_Sound_2023_0522_V008_1.mp4"
          width="100%"
          height="auto"
          autoPlay
          controls
          onEnded={handleVideoEnded}
          ></video>
      </Modal.Body>
    </Modal>
  );
}
