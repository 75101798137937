import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

const MouseContent = styled.span`
  color: white;
  position: absolute ;
`

function MouseTracker(props) {

  const [coords, setCoords] = useState({ x: null, y: null });
  const h1Ref = useRef(null);

  useEffect(() => {
    function handleMouseMove(event) {
      setCoords({ x: event.clientX, y: event.clientY });
    }

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    
    function updatePosition() {
      if (h1Ref.current) {
        h1Ref.current.style.left = `${coords.x}px`;
        h1Ref.current.style.top = `${coords.y}px`;
      }
      requestAnimationFrame(updatePosition);
    }

    updatePosition();
  }, [coords]);

  useEffect(() => {
    if (props.text) {
      let newText = '';
      Object.keys(props.text).forEach((key) => {
        newText += props.text[key] + ' ';
      });

      if (h1Ref.current) {
        h1Ref.current.innerText = newText.trim();
      }
    }
  }, [props.text]);

  return (
    <div>
      <MouseContent ref={h1Ref}>Mouse Tracker</MouseContent>
    </div>
  );
}

export default MouseTracker;