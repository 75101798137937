import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Context from '../../state/context';
import { ReactComponent as SoundOnSvg } from '../../icons/sound-on.svg';
import { ReactComponent as SoundOffSvg } from '../../icons/sound-off.svg';

const AudioControl = styled.div`
    display: block;
`
const ToggleStyle = styled.div`
    position: absolute;
    left: 10px;
    bottom: 20px;
`
const ToggleWrapper = styled.div`
    width: 60px;
    height: 60px;
    height: min-content;
    cursor: pointer;
`
const SFX = styled.p`
   color: #fff;
`
export default function Toggle() {

  const { state, updateToggleBackgroundAudio } = useContext(Context);

  //Toggles Audio (if SFX playing - will cut that)
  const togglePlay = () => {
    // setIsPlaying(!isPlaying);
    updateToggleBackgroundAudio(!state.toggleBackgroundAudio);
  };

  return (
    <ToggleStyle>
      <ToggleWrapper onClick={togglePlay}>
        <p>{ state.toggleBackgroundAudio ? <SoundOnSvg /> : <SoundOffSvg />}</p>
      </ToggleWrapper>
    </ToggleStyle>
  )
}