import "./App.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";
import Home from "./pages/home";
import About from "./pages/about";
import Resources from "./pages/resources";
import Loader from "./components/loader/loader";
import Toggle from "./components/audio/toggle";
import Audio from "./components/audio/audio";
import MouseTracker from "./components/mouseTracker/mouseTracker";
import Nav from "./components/nav/nav";
import { Campaign, FooterLogo } from "./components/campaign/campaign";
import Context from "./state/context";

const Container = styled.div`
  text-align: center;
  position: relative;
  height: 100vh;
  height: 100dvh;
  background: #000;
`;

const Login = styled.form`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const App = () => {
  const location = useLocation();
  const { state, setIsVerified } = useContext(Context);
  console.log("AppState", state);

  const checkPassword = () => {
    const isPassword = document.getElementById("password").value;

    if (isPassword === "JumpBluePie") {
      setIsVerified(true);
    } else {
      alert("Sorry, please try again.");
    }
  };

  return (
    <Container>
      {/* {state.isVerified ? (
        <> */}
          <AnimatePresence mode='wait' initial={false}>
            <Routes location={location} key={location.pathname}>
              <Route path='/' element={<Loader />} />
              <Route path='/home' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/resources' element={<Resources />} />
            </Routes>
          </AnimatePresence>
          <Toggle />
          <Audio />
          <MouseTracker text={" "} />
          <Nav />
          <Campaign />
          <FooterLogo />
        {/* </>
      ) : (
        <Login onSubmit={checkPassword}>
          <input style={{ padding: "8px" }} id='password' name='password' type='password' />
          <button>Submit</button>
        </Login>
      )} */}
    </Container>
  );
};

export default App;
