import React, { useState } from "react";
import styled from "styled-components";
import { Button,ShareButton } from "../button/button";
import { ReactComponent as InfoSvg } from "../../icons/info.svg";
import { ReactComponent as MoneySvg } from "../../icons/monetization.svg";
import { ReactComponent as ShareSvg } from "../../icons/share-alt.svg";
import { ReactComponent as ShareSmSvg } from "../../icons/share-sm.svg";
import { ReactComponent as InfoSmSvg } from "../../icons/info-sm.svg";
import { ReactComponent as BookSmSvg } from "../../icons/book.svg";
import { BrowserView, MobileView } from "react-device-detect";

const ButtonMenuStyle = styled.div`
  width: 530px;
  height: auto;
  position: absolute;
  right: 20px;
  top: 20px;
`;

export default function Nav() {

  return (
    <ButtonMenuStyle>
      <BrowserView>
        <Button icon={<InfoSvg />} style='low' text='About' link='/about' />
        <Button icon={<BookSmSvg />} style='low' text='Resources' link='/resources' />
        <Button
          icon={<MoneySvg />}
          style='medium'
          text='Donate'
          blank={true}
          link='https://fundraise.givesmart.com/form/BoGH6A?vid=y7tjx'
       />
        <ShareButton icon={<ShareSvg />} style='high' text='Share' onClick="handleShare" />
      </BrowserView>
      <MobileView className='brand-mobile'>
        <Button className='info' icon={<InfoSmSvg />} style='low' text='About' link='/about' />
        <Button className="resources" icon={<BookSmSvg />} style='low' text='Resources' link='/resources' />
        <ShareButton icon={<ShareSmSvg />} style='low' text='Share' onClick="handleShare" type="share" />
      </MobileView>
    </ButtonMenuStyle>
  );
}
