import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const Paging = ({ totalPages, currentPage, onPageChange }) => {
  const pages = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(
      <Pagination.Item
        key={i}
        active={i === currentPage}
        onClick={() => onPageChange(i)}
      >

      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      {pages}
    </Pagination>
  );
}

export default Paging;
