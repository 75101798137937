import styled from "styled-components";
import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Nav from "../components/nav/nav";
import Context from "../state/context";
import { PlayAudioBtnDefault, Button } from "../components/button/button";
import { ReactComponent as ASCOLogo } from "../icons/Logo-ASCO-Stacked.svg";
import { Howl } from "howler";

const ContentBody = styled.div`
  color: white;
  text-align: left;
  position: absolute;
  max-width: 850px;
  width: 100%;
  max-height: 85vh;
  overflow: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #4d4d4d;
  padding: 40px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
`;

const CloseBtn = styled(Link)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const audioClipBertish = new Howl({
  src: ["./audio/ConnBertish.mp3"],
});

const audioClipRadio = new Howl({
  src: ["./audio/The-Most-Beautiful-Sound_RADIO_270323_21h.mp3"],
});

function About() {
  const { updateCurrentPage, updateToggleBackgroundAudio} = useContext(Context);
  const [isPlayingBertish, setIsPlayingBertish] = useState(false);
  const [isPlayingRadio, setIsPlayingRadio] = useState(false);
  // const downloadSound = "CANJA_GreyNY_ASCO_The-Most-Beautiful-Sound_SPOT_042123_13h.mp3.zip";
  const downloadSound = "ASCO_MostBeautifulSound_CellSounds.mp3.zip";


  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = `./audio/${downloadSound}`;
    link.download = downloadSound;
    link.click();
  };

  const handleClick = () => {
    updateCurrentPage(1);
  };

 const togglePlay = (audioClip, setIsPlaying) => {
  if (audioClip.playing()) {
    audioClip.pause();
    setIsPlaying(false);
    updateToggleBackgroundAudio(true);
  } else {
    // Pause other audio clips before playing the current one
    if (audioClip === audioClipBertish) {
      audioClipRadio.pause();
      setIsPlayingRadio(false);
    } else if (audioClip === audioClipRadio) {
      audioClipBertish.pause();
      setIsPlayingBertish(false);
    }

    audioClip.play();
    setIsPlaying(true);
    updateToggleBackgroundAudio(false);
  }
};


  const togglePlayAudioBertish = () => {
    togglePlay(audioClipBertish, setIsPlayingBertish);
  };

  const togglePlayAudioRadio = () => {
    togglePlay(audioClipRadio, setIsPlayingRadio);
  };

  return (
    <motion.div
      transition={{
        delay: 0.5,
        duration: 0.5,
        default: { ease: "easeInOut" },
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ContentBody>
        <div className='container about'>
          <CloseBtn to='/home' onClick={handleClick}>
            <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M20.6663 12.2733L19.7263 11.3333L15.9997 15.06L12.273 11.3333L11.333 12.2733L15.0597 16L11.333 19.7266L12.273 20.6666L15.9997 16.94L19.7263 20.6666L20.6663 19.7266L16.9397 16L20.6663 12.2733Z'
                fill='white'
              />
              <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='white' />
            </svg>
          </CloseBtn>
          <div className='row my-7 text-center'>
            <div className='col'>
              <h1 className='mb-7'>Resources</h1>
              <hr className='my-9' />
              <h2 className='my-7 text-center'>Feel empowered</h2>

              <p className='lead text-center'>Enduring cancer is difficult. There are resources available to help you.</p>
              <hr className='my-9' />
              <h2 className='my-7 text-center'>HEAR THE SOUND OF CANCER CELLS BEING DESTROYED</h2>
              <p className='my-7'><Button icon={""} onClick={downloadFile} style='high' text='download the sound' /></p>

              <p>
                It's incredible how inspiring a simple sound can be for those who need to hear it the most.
              </p>
              <p>
                If you are a healthcare professional, a caregiver, or a patient who wants to use the Most Beautiful Sound, download the sound here.
              </p>

              <hr className='my-9' />
              <h2 className='my-7 text-center'>Use Resilience</h2>
              <p className='lead text-center'>
                The emotional toll from a cancer diagnosis can be just as devastating as the physical damage done by cancer itself.
              </p>
            </div>
          </div>
          <div className='row my-7'>
            <div className='col-12'>
              <img src='./images/03_cancer_net_logo.png' width={"300px"} height={"auto"} alt='' />
              <p className='py-4 px-10'>
                Well-informed patients are their own best advocates and invaluable partners for physicians. Cancer.Net provides timely, comprehensive information to help patients and families make informed health care decisions. The best cancer care starts with the best cancer information.
              </p>
            </div>
          </div>
          {/* <div className='row my-7 text-center video-panel'>
            <div className='col'>
              <h3 className='mb-3'>Conn Bertish</h3>
              <p className='video'>Brain cancer survivor, and founder of Cancer Dojo</p>
              <p>
                "It serves not only as a testament to the growing collaboration between creativity, technology, and medicine, but also as a tool that patients
                can use to meditate, visualize, and manifest, while harnessing the science of psychoneuroimmunology to build resiliency and enable a more
                positive outcome."
              </p>
             <PlayAudioBtnDefault
              label={isPlayingBertish ? "Pause" : "Listen"}
              handlePlay={togglePlayAudioBertish}
              isPlaying={isPlayingBertish} />
            </div>
          </div> */}
          <div className='row my6 text-center'>
            <div className='col'>
              <h3>Get the App</h3>
              <Link to='https://play.google.com/store/apps/details?id=com.fueled.cancernet&hl=en&gl=US&pli=1'>
                <img src='./images/google-play-badge.png' width={"245px"} height={"auto"} alt='' />
              </Link>
              <Link to='https://apps.apple.com/us/app/cancer-net-mobile/id433501257'>
                <img src='./images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917 1.png' width={"191px"} height={"auto"} alt='' />
              </Link>
            </div>
          </div>
          {/* <div className='row my-7 text-center video-panel'>
            <div className='col'>
              <h2 className='my-4 text-center'>Listen to our radio spot</h2>
              <p>It's hard to put into words what it feels like to hear a doctor say "you have cancer." The same can be said about what it feels like to hear those same cancer cells being destroyed. </p>
              <PlayAudioBtnDefault
              label={isPlayingRadio ? "Pause" : "Listen"}
              //handlePlay={togglePlayAudioRadio}
              isPlaying={isPlayingRadio} />
              </div>
          </div> */}
          <div className='row text-center my-7'>
              <div className='col'>
              <Link
              target="_blank"
              to='https://fundraise.givesmart.com/form/BoGH6A?vid=y7tjx'
             >
                <img src='./images/bannerv2.png' width={"100%"} height={"auto"} alt='' />
              </Link>
            </div>
          </div>
          <div className='row my-7'>
            <div className='col'>
              <div style={{'display' : 'none'}} className='text-center my-7'>
                <ASCOLogo />
              </div>
            </div>
          </div>
        </div>
      </ContentBody>
      <Nav />
    </motion.div>
  );
}
export default About;
