import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const StoryContent = styled.div`
  height: 300px;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    height: 240px !important;
  }
`;

const TranscriptText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transition: top 0.25s linear;
  transform: translateY(275px);
`;

const Transcript = ({ isPlayingStory, audioStoryRef, copy }) => {
  const transcriptRef = useRef(null);
  const transcriptCopyRef = useRef(null);

  const updateTranscriptPosition = () => {
    const audio = audioStoryRef.current;
    const transcript = transcriptRef.current;
    const transcriptCopy = transcriptCopyRef.current;

    if (!audio || !transcript) return;

    const audioDuration = audio.duration;
    const audioCurrentTime = audio.currentTime;
    const transcriptHeight = transcriptCopy.scrollHeight;
    const clientHeight = transcript.clientHeight;
    const scrollEnd = transcriptHeight + clientHeight;

    const scrollAmount = (audioCurrentTime / audioDuration) * scrollEnd;
    transcript.firstChild.style.top = `-${scrollAmount}px`;
  };
  useEffect(() => {
    const audio = audioStoryRef.current;

    if (isPlayingStory) {
      audio.addEventListener("timeupdate", updateTranscriptPosition);
    } else {
      audio.removeEventListener("timeupdate", updateTranscriptPosition);
    }

    return () => {
      audio.removeEventListener("timeupdate", updateTranscriptPosition);
    };
  }, [isPlayingStory, audioStoryRef]);

  return (
    <div className="story-transcript-wrapper">
      <StoryContent className="story-transcript my-4" ref={transcriptRef}>
        <TranscriptText
          ref={transcriptCopyRef}
          className="transcript-copy text-fine"
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      </StoryContent>
    </div>
  );
};

export default Transcript;
